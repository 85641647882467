.Main {
  height: 100vh;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.content-wrap {
  flex: 1;
}
html,
body {
  width: 100%;
  background-color: rgba(0, 0, 0, 1);
}
