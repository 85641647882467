.project {
  max-width: 1000px;
}
.project .links {
  margin: 5px;
}
.project p {
  color: rgb(0, 0, 0) !important;
  /* font-weight: bold; */
}
.project .title {
  display: grid;
  align-items: center;
  justify-content: center;
}

.project .video {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.project .video .react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.project small {
  font-size: medium;
}
