.Content {
  /* position: absolute; */
  background-color: rgb(255, 255, 255) !important;
  border: 5px solid grey;
  padding: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-direction {
  flex-direction: row;
}

@media screen and (max-width: 500px) {
  .flex-direction {
    flex-direction: column;
  }
}
