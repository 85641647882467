.link-container {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.details {
  color: rgb(8, 255, 131);
  size: 1vmin;
}
.header {
  color: rgb(217, 255, 0);
  font-weight: bold;
}
