.Intro {
  display: grid;
  align-items: center;
  justify-content: center;
}

.Intro .text {
  color: blue;
  width: 100px;
}
.home p {
  color: rgba(243, 235, 235, 0.644);
}
