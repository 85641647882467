.Container {
  position: relative;
  min-height: 300px;
  max-height: auto;
  display: flex;
  justify-content: space-around;
}

/* Style the video: 100% width and height to cover the entire window */
.Video {
  width: 80%;
  height: auto;
}

/* Add some content at the bottom of the video/page */
.Content {
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.SubContent {
  text-align: center;
  padding: 10px;
}
.SubContent p {
  color: #ffffffe1;
}
.SubContent button {
  font-weight: bold;
  margin-top: 30px;
  color: #f1f1f1;
  border: #f1f1f1 1px solid;
  padding: 10px 5px;
  background-color: hsla(0, 0%, 0%, 0);
  font-size: 1.25rem;
  border-radius: 5px;
}
.SubContent button:hover {
  background-color: #f1f1f1;
  color: #000000c0;
}

.SubContent img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  border: #0c0606 1px solid;
}
